import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import HeaderUnderlined from "../components/HeaderUnderlined";
import Layout from "../components/layout";
import Seo from "../components/Seo";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import styled from "styled-components";
const TagTemplate = ({ data, pageContext }) => {
  // const next = pageContext.next;
  // const previous = pageContext.previous;
  const {
    name: tagTitle,
    relationships,
    description,
    field_tag_image,
  } = data.allTaxonomyTermTag.nodes[0];

  // image consts
  const thumbnail = getImage(
    relationships.field_tag_image.localFile.childImageSharp
  );
  return (
    <>
      <Layout>
        <Seo title={`Tag: ${tagTitle}`} />

        <Container fluid="lg">
          <Row>
            <Col xl={3} lg={3} md={4} sm={6} xs={12} className="wavebackground">
              <div>
                <GatsbyImage image={thumbnail} alt={field_tag_image.alt} />
              </div>
            </Col>
            <Col className="p-5 bg-white">
              <HeaderUnderlined titlesrc={`Tag: ${tagTitle}`} />
              {description !== null && (
                <Alert variant="success">
                  <div
                    className="pb-2"
                    dangerouslySetInnerHTML={{ __html: description.processed }}
                  />
                </Alert>
              )}
              <div className="pb-2">
                <Container>
                  <Row className="pb-5">
                    {relationships.node__article.map((articleNode) => {
                      const {
                        id,
                        body,
                        title,
                        path,
                        created,
                        articleNodeRelationships,
                      } = articleNode;
                      return (
                        <React.Fragment key={id}>
                          <Col xs={12}>
                            <CardBlock>
                              <CardTitle>
                                <span>{title}</span>
                                <Small>
                                  {articleNodeRelationships.field_writer.name}{" "}
                                  {created}
                                </Small>
                              </CardTitle>

                              <CardArticle
                                dangerouslySetInnerHTML={{
                                  __html: body.summary,
                                }}
                              />
                              <Link
                                to={path.alias}
                                className="text-decoration-none"
                              >
                                <CardFooter>
                                  <span className="text-white">
                                    Читать дальше
                                  </span>
                                </CardFooter>
                              </Link>
                            </CardBlock>
                          </Col>
                        </React.Fragment>
                      );
                    })}
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};
export const query = graphql`
  query ($tagPageId: String!) {
    allTaxonomyTermTag(filter: { id: { eq: $tagPageId } }) {
      nodes {
        name
        field_tag_image {
          alt
        }
        relationships {
          field_tag_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 70
                  width: 500
                  height: 500
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          node__article {
            id
            body {
              summary
            }
            title
            path {
              alias
            }
            created(formatString: "DD-MM-YYYY")
            articleNodeRelationships: relationships {
              field_writer {
                name
              }
            }
          }
        }
        description {
          processed
        }
      }
    }
  }
`;
export default TagTemplate;

const CardBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
`;

const CardTitle = styled.div`
  /* padding: 0.5rem; */
  font-size: 1.25rem;
  font-family: Oswald;
  font-weight: 500;
`;
const Small = styled.small`
  display: flex;
  font-size: 0.9rem;
  font-family: Open Sans, sans-serif;
`;
const CardArticle = styled.article`
  padding-block: 0.5rem;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
`;
const CardFooter = styled.div`
  padding: 0.5rem;
  -webkit-transition: background-color 500ms ease;
  -moz-transition: background-color 500ms ease;
  -o-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  transition: background-color 0.5s ease;
  background-color: #1e2b37;
  font-family: Oswald;
  font-weight: 500;
  &:hover {
    -webkit-transition: background-color 500ms ease;
    -moz-transition: background-color 500ms ease;
    -o-transition: background-color 500ms ease;
    transition: background-color 500ms ease;
    background-color: #344352;
  }
`;
